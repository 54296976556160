import { render, staticRenderFns } from "./PackList.vue?vue&type=template&id=d84c85c2&scoped=true&"
import script from "./PackList.vue?vue&type=script&lang=js&"
export * from "./PackList.vue?vue&type=script&lang=js&"
import style0 from "./PackList.vue?vue&type=style&index=0&id=d84c85c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d84c85c2",
  null
  
)

export default component.exports