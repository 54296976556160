var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-query"},[_c('header',{staticClass:"page-header"},[_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('span',[_vm._v(_vm._s(_vm.title))]),_c('span')],1),_c('main',{staticClass:"page-body"},[_c('div',{staticClass:"flexbox"},[_c('van-search',{staticClass:"flex-fill",attrs:{"placeholder":_vm.$t('Package.请输入搜索关键词')},on:{"search":_vm.onSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),(_vm.list.length > 0)?_c('section',[_c('section',{staticClass:"list padding",style:([_vm.bodyHeight])},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"card"},[[_c('div',{staticClass:"card-body flexbox space-bt",on:{"click":function($event){if (item.istatus <= 0) {
                  _vm.$router.push({
                    name: 'PackageEdit',
                    query: { id: item.PK_ID },
                  });
                }}}},[_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.$t('Package.快递单号'))+"："+_vm._s(_vm._f("empty")(item.delivery_no))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Package.唛头'))+"："+_vm._s(_vm._f("empty")(item.sShippingMark))+" ")]),_c('div',[_vm._v(_vm._s(_vm.$t('Package.箱号'))+"："+_vm._s(_vm._f("empty")(item.sBagNo)))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Package.进仓时间'))+"："+_vm._s(_vm._f("empty")(item.createtime))+" ")]),_c('div',[_vm._v(_vm._s(_vm.$t('Package.物品名称'))+"："+_vm._s(item.goods_name))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Package.英文名称'))+"："+_vm._s(item.sEnGoodName)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('Package.进仓编号'))+"："+_vm._s(_vm._f("empty")(item.sCode)))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Package.订单编号'))+"："+_vm._s(_vm._f("empty")(item.OrderNo))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Package.单个货值'))+"："+_vm._s(_vm._f("empty")(item.single_price))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Package.整体货值'))+"："+_vm._s(_vm._f("empty")(item.fDeclareAmount_LC))+" ")])]),_c('img',{class:item.sPicUrl == '' ? 'noimg' : 'img',attrs:{"src":item.sPicUrl == '' || item.sPicUrl == null
                    ? _vm.emptyImg
                    : _vm.tms_img_url + item.sPicUrl,"alt":""},on:{"click":function($event){return _vm.onImgPreview(item.sPicUrl)}}})]),_c('footer',{staticClass:"card-footer"},[_vm._v(" "+_vm._s(_vm.$t('Package.箱数'))+"："),_c('span',{staticClass:"foot-desc"},[_vm._v(_vm._s(item.iBags))]),_c('span',{staticClass:"pull-right"},[_vm._v(" "+_vm._s(_vm.$t('Package.状态'))+"："),_c('span',{staticClass:"round-label"},[_vm._v(_vm._s(_vm.pkgMap[item.istatus]))])])])]],2)}),0),_c('div',{staticClass:"pagination"},[_c('van-pagination',{attrs:{"total-items":_vm.totalCount,"items-per-page":_vm.limit,"show-page-size":4,"force-ellipses":""},on:{"change":_vm.onSearch},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]):_c('section',{staticClass:"padding no-item"},[_c('div',{},[_c('img',{attrs:{"src":_vm.emptyImg}})]),_c('div',{staticClass:"nodesc"},[_vm._v(_vm._s(_vm.$t('Package.暂时没有记录')))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }